.Cardone{
    width: 300px;
    margin: 15px;
    padding: 10px;
    height: 325px;
    .card-text{
        font-size: 14px;
    }
    .card-image{
        height:150px
    }
    .card-body{
        overflow: hidden;
    }
}

.edit-card-icon{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    right: 13px;
    top: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
