/* EditModal.css */

/* Styling for modal header */
.ModalHeader {
    background-color: #0b3d6a;
    color: white;
  }
  
  /* Styling for modal title */
  .ModalTitle {
    margin: 0;
    font-size: 1.25rem;
  }
  
  /* Styling for modal body */
  .ModalBody {
    padding: 20px;
  }
  
  /* Styling for form labels */
  .FormLabel {
    font-weight: bold;
  }
  
  /* Styling for form inputs */
  .FormControl {
    border-color: #0b3d6a;
  }
  .ModalCloseButton {
    color: white;
  }
  /* Styling for submit button */
  .SubmitButton,.SubmitButton:hover {
    background-color: #0b3d6a;
    border-color: #0b3d6a;
  }
  
  /* Styling for cancel button */
  .CancelButton, .CancelButton:hover{
    background-color: white;
    border-color: #0b3d6a;
    color: #0b3d6a;
  }
  
  /* Styling for form fields */
  .FormField {
    margin-bottom: 15px;
  }
  
  /* Styling for form groups */
  .FormGroup {
    margin-bottom: 20px;
  }
  
  /* Styling for modal footer */
  .ModalFooter {
    justify-content: flex-end;
    padding: 20px;
  }
  
  /* Styling for modal backdrop */
  .ModalBackdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }
