.channel-setup-header {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 30px;

    .channel-setup-search {
        flex-grow: 3;
        padding-right: 50px;
    }
}
// .cms-table-list {
//     .table-action-group {
//         .action-label {
//             min-width: 80px;
//         }
//     }

//     .col1 {
//         width:25%;
//     }

//     .col2 {
//         width: 20%;
//     }

//     .col3 {
//         width: 15%;
//     }

//     .col4 {
//         width: 15%;
//     }

//     .col5 {
//         width: 15%;
//     }
//     .col6 {
//         width: 10%;
//     }
// }

// .table-pagination .pagination-count .pagination-arrow {
//     width: 70px;
// }

// .tableLoader {
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }
.cms-table-list {
    td{
        font-size: 16px;
    }
}

.switch-loader {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
    position: absolute;
    left: 50%;
    z-index: 1000;
    background: rgba(0,0,0,0.4);
    margin-left: -12px;
}
.switch-loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 3px solid #fff;
    animation: prixClipFix 2s linear infinite ;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
}

@keyframes rotate {
    100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}


.orginazer-setup-header{
display: flex;
justify-content: space-between;
color: #fff !important;
}

.mantine-Drawer-inner > section, .mantine-Drawer-inner > section .mantine-Drawer-header{
background: transparent !important;
button{
    background-color: white;
}
}
.react-datepicker-popper{
    left: 0 !important;
}