
.dashboard-header {
    width: 100%;
    background-color: #0b3d6a;
    padding: 10px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 16px rgba(153, 153, 153, 0.08);
    position: fixed;
    z-index: 2;
    color: white;


    a{
        color: white;
    }
    .logo {
        max-width: 135px;
        display: inline-block;
        vertical-align: middle;
        img {
            max-width: 100%;
        }
        span{
            font-size: 36px;
            font-weight: 700;
            color: black;
            letter-spacing: 2px;
            font-family: serif;
        }
    }
    .welcome-name {
        display: inline-block;
        vertical-align: middle;
        padding-left: 80px;
        h5 {
            margin: 0;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
        }
        .date {
            span {
                color: #646464;
                font-size: 14px;
                line-height: 22px;
            }
        }
    }

    .dropdown.show{
        top:64px;
        width:150px;
    }
}
