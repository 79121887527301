.expand-content {
  gap: 20px 0px
}

.scroll-height {
  overflow-y: auto;
  height: 600px;
}

.scroll-height::-webkit-scrollbar {
  width: 3px;
  /* Set the width of the scrollbar */
}

.scroll-height::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Set the background color of the track */
}

/* Change the appearance of the scrollbar thumb (the draggable part) */
.scroll-height::-webkit-scrollbar-thumb {
  background: #000000;
  /* Set the background color of the thumb */
  border-radius: 6px;
  /* Add rounded corners to the thumb */
}

/* Change the appearance when the scrollbar is hovered over */
.scroll-height::-webkit-scrollbar-thumb:hover {
  background: #E0E0E0;
}




.right-bar {
  height: 100%;
  display: block;
  width: 210px;
  margin: auto;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #E6E6E6;
  background: #FFF;
  box-shadow: 0px 8px 24px 0px rgba(16, 24, 40, 0.08);

  .right-head {
    color: #333;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 20px */
  }
}

.detail-inputs {
  width: 100%;
  background: white !important;
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #E6E6E6;

  color: #222;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.2px;

  &:focus,
  &:active {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);

  }
}


.breadcrumbs a {
  color: #999;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 14px */
  color: #999999;
}

.selected-block {
  // border: 1px solid #D22F26 !important;
  // box-shadow: 0px 16px 32px rgb(117 94 135 / 30%);

  background: linear-gradient(#F7F7F7, #F7F7F7) padding-box,
    linear-gradient(to right, #22BBBE, #7DAD41, #F4B719, #E55824, #B52C80) border-box !important;
  border-radius: 6px !important;
  border: 1px solid transparent !important;
  box-shadow: 0px 16px 32px 0px rgba(16, 24, 40, 0.16) !important;
}


.video-container {
  display: flex;
  // justify-content: space-between;
  gap: 16px;
}

@media (max-width: 991px) {
  .video-container {
    justify-content: center;
    margin: 0 4px;
  }
}

.video-title {
  color: #0b3d6a;
  font: 700 20px/140% Roboto, -apple-system, Roboto, Helvetica, sans-serif;
}

.image-container {
  border-radius: 6px;
  border: 0.5px solid #e6e6e6;
  background-color: #fff;
  width: 36px;
  max-width: 100%;
  flex-direction: column;
  padding: 6px;
  height: 36px;
}

.image {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 100%;
  overflow: hidden;
}

.video-details {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.detail-row {
  display: flex;
  flex-direction: column;
  gap: 8px;

  input,
  select {
    height: 45px;

  }
}

.detail-label {
  font-weight: bold;
}

.detail-input {
  margin-right: 20px;
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  background: #F9F9F9;

  border: 1px solid #E6E6E6;
}

.detail-label:last-child {
  margin-top: 8px;
}

.upload-section {
  justify-content: space-between;
  margin: 22px 0px;
}

.folder-icon {
  height: 110px;
  position: relative;
  border-radius: 8px;
  border: 0.5px dotted rgb(210, 47, 38);
  padding: 2px;
  background-color: white;

  @media (max-width: 1400px) {
    height: 80px;


  }




  .background {
    display: flex;
    justify-content: center;
    /* Horizontally center */
    align-items: center;
    /* Vertically center */
    /* Add any other styles you need */


    width: 100%;
    height: 100%;


    background: rgb(254, 249, 249);
    border-radius: 8px;

  }



  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Center vertically */
    align-items: center;
    /* Center horizontally */
    /* Add any other styles you need */


    img {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
  }

  // .icon {
  //   width: 24px;
  //   height: 24px;
  //   left: 34px;
  //   top: 0px;
  //   position: absolute;
  //   display: inline-flex;
  // }

  // .label {
  //   width: 92px;
  //   height: 24px;
  //   left: 0px;
  //   top: 32px;
  //   position: absolute;
  //   display: inline-flex;
  //   flex-direction: column;
  //   justify-content: flex-start;
  //   align-items: center;
  //   gap: 3px;
  // }

  .title {
    color: #646464;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }


}

/* CategoryItem.css */
.label-box {
  width: 25%;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: white;
  border-radius: 6px;
  border: 0.5px #E6E6E6 solid;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;


  .icon-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: inline-flex;
  }

  .folder-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;


  }

  .folder-icon {
    width: auto;
    height: 19.87px;
  }

  .text {
    color: #0D0D0D;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 700;
    letter-spacing: 0.10;
    word-wrap: break-word;
  }
}

.blank-text {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  width: 232px;
  height: 56px;
  color: #755E87;
}

.delete-icon {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  right: 8px;
  top: 8px;
  background: white;
  width: 15px;
  height: 22px;

}


.right-content {
  padding: 20px;
  // width: 442px;
  min-height: 74.5vh;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #E6E6E6;
  background: #FFF;
  box-shadow: 0px 8px 24px 0px rgba(16, 24, 40, 0.08);

  // @media screen and (max-width:1800px) {
  // width:auto;

  // }

}

.dashboard-content {
  .right-content {
    padding: 20px 0;

    >* {
      padding: 0 20px;
    }

    .part-height {
      +div {
        box-shadow: 0px -4px 10px 0px rgba(169, 169, 169, 0.15);
        padding-top: 14px;
      }
    }
  }
}

.detail-input {


  padding: 12px, 16px, 12px, 16px;
  border-radius: 6px;
  gap: 8px;

}

textarea {
  height: 100px !important;
}

.back-image {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.video-channel-list {
  // display: flex;

  flex-wrap: wrap;

  .block {
    cursor: pointer;

    padding: 2px;
    border-radius: 6px;
    border: 0.5px;

    margin: 0 36px 36px 0;
    width: 120px;
    height: auto;
    border: 1px solid #f6f1f1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
    position: relative;
    transition: all 0.3s;

    &:hover {
      box-shadow: 0px 8px 16px 0px rgba(16, 24, 40, 0.08);
      background: linear-gradient(#F7F7F7, #F7F7F7) padding-box, linear-gradient(to right, #22BBBE, #7DAD41, #F4B719, #E55824, #B52C80) border-box;
      border-radius: 6px;
      border: 1px solid transparent;
      box-shadow: 0px 16px 32px 0px rgba(16, 24, 40, 0.16);
    }

    img {
      object-fit: contain;
      vertical-align: middle;
    }

    .img-block {
      width: 100%;
      height: 48px;
      padding: 12px 0;
      background: #F0E9F5;
    }

    &.upload {
      position: relative;
    }

    .text-block {
      width: 100%;
      overflow: hidden;
      font-family: Roboto;
      font-size: 12px;
      height: auto;
      font-weight: 500;
      padding: 4px;
      letter-spacing: 0em;
      text-align: center;

    }

    .dropdown {
      position: absolute;
      right: 7px;
      top: 7px;


    }
  }

  .active {
    box-shadow: 0px 8px 16px 0px rgba(16, 24, 40, 0.08);
    background: linear-gradient(#F7F7F7, #F7F7F7) padding-box, linear-gradient(to right, #22BBBE, #7DAD41, #F4B719, #E55824, #B52C80) border-box;
    border-radius: 6px;
    border: 1px solid transparent;
    box-shadow: 0px 16px 32px 0px rgba(16, 24, 40, 0.16);
  }
}

// .FilesDragAndDrop {
//     .FilesDragAndDrop__area {
//       width: 300px;
//       height: 200px;
//       padding: 50px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       flex-flow: column nowrap;
//       font-size: 24px;
//       color: #555555;
//       border: 2px #c3c3c3 dashed;
//       border-radius: 12px;

//       .area__icon {
//         font-size: 64px;
//         margin-top: 20px;
//       }
//     }
//   }
.box__input {
  outline: 2px dashed #ff0000;
  outline-offset: -10px;
  -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  transition: outline-offset .15s ease-in-out, background-color .15s linear;
  width: 116px;
  height: 96px;
  border-radius: 15px;
  display: inline-block;
  background: url(../../assets/images/uil_upload.svg) no-repeat center top 20px;
  position: relative;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    background-color: rgba(227, 157, 133, 13%);
    z-index: 1;
    width: 88px;
    height: 68px;
    top: 14px;
    left: 14px;
    border-radius: 6px;
  }

  input {
    width: 70%;
    height: 70%;
    opacity: 0;
    overflow: hidden;
    position: relative;
    left: 15%;
    top: 15%;
    border-radius: 15px;
    z-index: 55;
  }

  .box-label {
    text-align: center;
    font-size: 10px;
    position: absolute;
    top: 50px;
    width: 100%;
    z-index: 5;
  }

  .box-size {
    text-align: center;
    font-size: 10px;
    position: absolute;
    top: 65px;
    width: 100%;
    z-index: 5;
  }
}

.imageexist {
  // outline: 2px dashed #ff0000;
  margin: auto;
  width: 400px;
  height: 370px;
  border-radius: 15px;
  display: inline-block;

  position: relative;
  cursor: pointer;

  padding: 7px;

  .image-show {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }

  a {
    line-height: 1.3;
    background-color: white;
    position: absolute;
    right: 10px;
    top: 10px;

    img {
      width: 15px;
      height: 15px;
    }
  }

  input {
    width: 70%;
    height: 70%;
    opacity: 0;
    overflow: hidden;
    position: relative;
    // left: 15%;
    // top: 15%;
    border-radius: 15px;
    z-index: 55;
  }

  // &:before {
  //   content: "";
  //   position: absolute;
  //   background-color: rgba(227, 157, 133, 13%);
  //   z-index: 1;
  //   width: 88px;
  //   height: 68px;
  //   // top: 14px;
  //   // left: 14px;
  //   border-radius: 6px;
  // }
}

.editTabs {
  margin: 20px;

  .tablist button {
    font-size: 18px;
    margin: 0px 10px;

    &[data-active="true"] {
      border-bottom: 2px solid #0b3d6a;
      color: #0b3d6a;
      font-weight: 500;
    }

  }

  .mantine-Tabs-panel {
    padding: 10px;
  }
}

.button-container .button1 {

  background: #0b3d6a;
  margin: 10px;
  letter-spacing: 1px;

  &:hover {
    background: white;
    color: #0b3d6a;
    border: 1px solid #0b3d6a;

  }

}
.button-container .button2 {
  background: white;
  color: #0b3d6a;
  border: 1px solid #0b3d6a;




}

.tablisting button {
  font-size: 18px;
  margin: 0px 10px;

  &[data-active="true"] {
    // border-bottom: 2px solid #0b3d6a;
    color: #0b3d6a;
    font-weight: 500;
  }


}

.m_8fb7ebe7:disabled,
.m_8fb7ebe7[data-disabled] {

  opacity: 1 !important;
  font-weight: 500 !important;
  background-color: #f7f7f7 !important;
  color: #323435 !important;

}