.dashboard{
.Card
 {
 
  

    box-shadow: 0 8px 16px rgba(66, 66, 66, 0.1); /* Add shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition for smooth hover effect */
  }
  
  .Card:hover {
    transform: translateY(-5px) !important; /* Add a slight lift effect on hover */
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2) !important; /* Enhance shadow on hover */
  }
  
 
  

}
//   .cms-table-list {
//     width: 100%;
//     border-collapse: collapse;
//     box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Add shadow */
//     overflow: hidden; /* Hide overflow content */
//     /* Add larger border radius */
//     background: linear-gradient(to bottom right, #f0f0f0, #e0e0e0); /* Add gradient background */
// th label{
//     color: #000000;
// } 

// }
  
//   th, td {
//     padding: 15px;
//     text-align: left;
//   }
  
//   th {
//     background-color: #ddd; /* Light gray background for table header */
//   }
  
//   tr:nth-child(even) {
//     background-color: #f9f9f9; /* Alternate row background color */
//   }
  
//   tr:hover {
//     background-color: #f0f0f0; /* Change background color on hover */
//   }
  
//   .pagination-count {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     margin-top: 20px; /* Add margin for separation */
//   }
  
//   .pagination-arrow a {
//     display: inline-block;
//     margin: 0 5px;
//     cursor: pointer;
//     transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition for smooth hover effect */
//   }
  
//   .pagination-arrow a:hover {
//     transform: translateY(-2px); /* Add lift effect on hover */
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Enhance shadow on hover */
//   }
  
  // .disable {
  //   pointer-events: none; /* Disable click event */
  //   opacity: 0.5; /* Reduce opacity for disabled elements */
  // }
 

