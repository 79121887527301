.modal-header {
    background-color: #0b3d6a;
 color: white;
    border-bottom: 1px solid #e5f3ff;
    .btn-close{
        color: white !important;
    }
}

.modal-body {
    font-size: 1.1em;
}

.modal-footer .btn-cancel,.modal-footer .btn-cancel:hover {
    color: #0b3d6a;
    background-color: white;
}

.modal-footer .btn-submit, .modal-footer .btn-submit:hover {
    background-color: #0b3d6a;
    border-color: #0b3d6a;
    color:white
}
