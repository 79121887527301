.fixed-column {
       position: fixed;
    height: 90%;
    z-index: 1;
    top: 76px;
    background-color: #eee;
    bottom: 0;
  }
  
  .scrollable-column {
    margin-left: 19%; /* Adjust this value according to the width of your fixed column */
  
  }
  