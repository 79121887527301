.profile-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .profile-content {
    text-align: center;
    width: 85%;
  }
  
  .profile-image {
    width: 150px; /* Adjust size as needed */
    height: 150px; /* Adjust size as needed */
    border-radius: 50%;
  }
  
  .profile-name {
    margin-top: 10px;
  }
  
  .profile-contact {
    margin-top: 5px;
  }
  
  .profile-contact-item {
    display: inline-block;
    margin-right: 10px;
  }
  
  .profile-about {
    margin-top: 10px;
  }
  
  .profile-left{
   float: left;
  }
  .profile-right{
   float: right;
  }
  
  .profile-total-sale,
  .profile-total-sales {
    display: inline-block;
    margin-right: 10px;
  }
  
  