.add-event-container {
    h1 {
        font-size: 1.2rem;
        font-weight: 700;
    }

    // .add-event-form {
    // }
}

.add-ticket {
    padding: 0.75rem;

    h2 {
        font-size: 1.2rem;
        font-weight: 700;
    }
}