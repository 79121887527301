.ticket-table {
    width: 100%;

    .filter-input {
        margin-bottom: 1rem;
        width: 100%;
    }

    .pagination {
        width:100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        p{
            margin: 0px;
        }
    }

    .MuiTableCell-root {
        &.MuiTableCell-head {
            background-color: #f5f5f5;
            font-weight: bold;
        }
    }

    .MuiIconButton-root {
        margin: 0 0.2rem;
    }
}
.react-datepicker-popper{
    left: 10% ;
}
.custom-dropdown {
    max-width: 300px; /* Adjust the width as needed */
    min-width: 200px; /* Adjust the width as needed */
  }
  .table-contain{
    input{
        padding:4px;
    }
    label{
        top:-7px !important;
    }
    input{
        height:2rem !important;
    }
  }