  .report-table-list {
        width: 100%;
        border-collapse: collapse;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Add shadow */
        overflow: hidden; /* Hide overflow content */
        /* Add larger border radius */
        background: linear-gradient(to bottom right, #f0f0f0, #e0e0e0); /* Add gradient background */
    th label{
        color: #000000;
    } 
    
    td{
        border-bottom: 1px solid #f0f0f0;
        height: 55px;
    }
      
      th, td {
        padding: 15px;
        text-align: left;
        background-color: white;
      }
      
      .theads {
        background-color: #e5f3ff; /* Light gray background for table header */
        
      }
      
      tr:nth-child(even) {
        background-color: #f9f9f9; /* Alternate row background color */
      }
      
      tr:hover {
        background-color: #f0f0f0; /* Change background color on hover */
      }
      
      .pagination-count {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px; /* Add margin for separation */
      }
      
      .pagination-arrow a {
        display: inline-block;
        margin: 0 5px;
        cursor: pointer;
        transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition for smooth hover effect */
      }
      
      .pagination-arrow a:hover {
        transform: translateY(-2px); /* Add lift effect on hover */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Enhance shadow on hover */
      }
      
      .disable {
        pointer-events: none; /* Disable click event */
        opacity: 0.5; /* Reduce opacity for disabled elements */
      }
      }
    




      .box {
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        overflow: hidden;
      }
      
      .box-content {
        padding: 20px;
        text-align: center;
   
      
      h1 {
        margin-bottom: 8px;
        font-size: 24px;
      }
      
      h2 {
        margin-bottom: 0;
        font-size: 18px;
      }
      }
      