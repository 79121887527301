.search-bar {
   
    display: flex;
    align-items: center;
   
    background-color: #f9f9f9;
   
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .search-bar input[type="text"] {
   
 
    font-size: 16px;
   
 
    outline: none;
    transition: all 0.3s ease;
  }
  
  .search-bar input[type="text"]:focus {
    border-color: #aaa;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  }
  
  .search-bar button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;

 
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  // .search-bar button:hover {
    // background-color: #0056b3;
    // color: white;
  // }
  